import React from "react";
import { Dialog, DialogTitle, Fade, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { forwardRef } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

// CSS
const useStyles = makeStyles((theme) => ({
  dialog: {
    "@media (max-width: 960px)": {
      width: "82%",
      margin: 0,
      padding: "20px 20px",
      height: "100%",
      maxHeight: "100%",
      position: "absolute",
      right: 0,
    },
  },
  dialogFull: {
    borderRadius: "16px",
    boxShadow: "none",
    "@media (max-width: 960px)": {
      width: "100%",
      margin: 0,
      padding: "20px 20px",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      position: "absolute",
      right: 0,
      borderRadius:0
    },
  },
  root: {
    padding: 0,
    "@media (max-width: 960px)": {
      height: 60,
      minHeight: 0,
      borderBottom: "2px solid #060606",
      // 도움말에만 적용
      backgroundColor: "#fff !important",
    },
  },
  [`@media print`]: {
    root: {
      display: "none !important",
    },
    dialog: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  toolbar: {
    padding: 0,
    minHeight: 0,
  },
  title: {
    width: "100%",
    "@media (min-width: 960px)": {
      padding: "34px 70px",
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "-0.6px",
      textAlign: "center",
      color: "#333333",
    },
    "@media (max-width: 960px)": {
      paddingTop: 22,
      lineHeight: 1.78,
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: "-0.38px",
      textAlign: "left",
      color: "#000000",
    },
  },
  close: {
    width: 24,
    height: 24,
    position: "absolute",
    "@media (min-width: 960px)": {
      top: 40,
      right: 42,
    },
    "@media (max-width: 960px)": {
      top: 22,
      right: 0,
    },
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return (
    <React.Fragment>
      <Hidden smDown>
        <Fade in={true} ref={ref} {...props} />
      </Hidden>

      <Hidden mdUp>
        <Slide
          direction="left"
          ref={ref}
          {...props}
          timeout={{ appear: 0, enter: 300, exit: 200 }}
        />
      </Hidden>
    </React.Fragment>
  );
});

type Props = {
  children?: any;
  title: string;
  isPopup?: boolean
  open: boolean;
  onClose: any;
  type?: string;
  useClose?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  transitionComponent?: any;
  disableBackdropClick?: boolean;
};

const KirdModal = (props: Props) => {
  const classes = useStyles();
  const { children, isPopup, open, onClose, type, ...other } = props;
  const useClose =
    typeof props.useClose !== "undefined" ? props.useClose : true;
  const transitionComponent =
    props?.transitionComponent === undefined
      ? Transition
      : props?.transitionComponent;
  const disableBackdropClick =
    typeof props.disableBackdropClick !== "undefined"
      ? props.disableBackdropClick
      : false;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={transitionComponent}
      onClose={onClose}
      fullWidth={other.fullWidth}
      maxWidth={other.maxWidth}
      classes={{ paper: other.fullWidth ? classes.dialogFull : classes.dialog }}
      disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle
        className={classes.root}
        style={
          props.type === "help" ? { background: "#ebf3ff" } : { background: "" }
        }
      >
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} style={isPopup ? {fontSize: "18px", fontWeight: "bold"} : {fontSize: "24px"}}>{props.title}</Typography>
          {useClose && (
            <IconButton
              color="inherit"
              aria-label="close"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </DialogTitle>

      {children}
    </Dialog>
  );
};

export default KirdModal;

import React, {forwardRef, useEffect, useState} from "react";
import styles from "./cdrPopup.module.css";
import clsx from "clsx";
import {useTranslation} from "next-i18next";
import {DialogContent, DialogActions, Grow} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import KirdModal from "components/common/KirdModal";
import {selectCodeList} from "shared/utils/CommonCode";
import {boolean, string} from "yup";

const Transition = forwardRef(function Transition(props: TransitionProps & {
  children?: React.ReactElement<any, any>
}, ref: React.Ref<unknown>) {
  return <Grow in={true} ref={ref} {...props} timeout={{appear: 0, enter: 500, exit: 200}}/>;
});

interface CodeType {
  id: number;
  code: string;
  group: string;
  name: string;
}

interface Props {
  open: boolean,
  onClose: Function,
  fullWidth?: boolean,
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl",
  myCareerLevel?: string,
  myJobField?: string,
  popupWords?: string,
  isPreview?: boolean,
  changeMyCdrStep?: Function,
}

export default function CdrStepPopup(props: Props) {
  const { t } = useTranslation();
  const { open, onClose, myCareerLevel, myJobField, popupWords = "", isPreview = false, changeMyCdrStep, ...other } = props;

  const [activeTab, setActiveTab] = useState("연구직");

  // 직무분야 목록
  const [jobFieldList, setJobFieldList] = useState<Array<CodeType>>([]);

  // 나의 직무, 경력단계와 일치하는지 확인
  const fnCheckIsMyCareerLevelAndJobField = (jobField: string, careerLevel: string) => {
    return jobField == myJobField && careerLevel == myCareerLevel;
  };

  // 탭 클릭 핸들러
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (jobFieldList?.length > 0 && myJobField != undefined && myJobField != "") {
      // myJobField가 read-only이기 때문에 수정할 수 있도록 임시 변수 선언
      let jobFieldCode = myJobField;

      // 기타로 입력이 들어온 경우에는 연구직을 띄우도록 요청받음.
      if(jobFieldCode == "4") jobFieldCode = "1";

      const myJobFieldName = jobFieldList.find(item => {
        if (item.code == jobFieldCode) return true;
      })?.name;

      setActiveTab(myJobFieldName);
    }
  }, [jobFieldList, myJobField]);

  useEffect(() => {
    setJobFieldList(selectCodeList("6105"));
  }, []);

  return (
    <KirdModal title={t("경력단계 확인")} maxWidth="xl" open={open} onClose={onClose} transitionComponent={Transition} {...other}>
      <DialogContent className={styles.popContentWrap}>
        {popupWords?.length > 0 &&
          <div
            className={styles.topGuide}
            dangerouslySetInnerHTML={{ __html: popupWords }}
          />
        }
        <div className={styles.cdrPop}>
          <div className={styles.cdrStepCont}>
            <div className={styles.cdrsTabWrap}>
              <ul className={styles.cdrsTab}>
                {["연구직", "연구관리직", "행정직"].map((tabName) => (
                  <li key={tabName}>
                    <button
                      type="button"
                      className={clsx(styles.btnTab, {
                        [styles.active]: activeTab === tabName, // 활성 탭에 active 클래스 적용
                      })}
                      onClick={() => handleTabClick(tabName)} // 클릭 이벤트
                    >
                      {tabName}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.cdrsTabContWrap}>
              <div
                className={clsx(styles.cdrsTabCont, {
                  [styles.show]: activeTab === "연구직", // 활성화된 컨텐츠에만 show 클래스 추가
                })}
              >
                <div className={styles.studyInfoTable}>
                  <table>
                    <caption>연구직 경력단계 정보로 구분, 신진, 중견, 리더로 구성되어있습니다.</caption>
                    <colgroup>
                      <col className={styles.col01}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti}>{t("구분")}</span>
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('1', '1') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("신진")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('1', '1') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('1', '2') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("중견")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('1', '2') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('1', '3') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("리더")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('1', '3') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>경력지향</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연구수행 전문성 향상을 통해 향후 다수 연구과제 책임 및 단일 연구팀 리더 역할을 준비하는 연구자
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          대내외적으로 연구역량을 인정받고 향후 대형 연구사업(과제) 책임 및 기관 내 연구조직 관리 역할을 준비하는 연구자
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          최상의 연구역량을 기반으로 글로벌 수준의 연구성과를 창출하고 국가적 연구를 주도할 수 있는 연구자
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>업무경력</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연간 3개 내외의 연구과제를 수행하는 연구자
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          소형 연구과제 기획 및 연구과제 책임자<br/>
                          (경력 8년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연간 3개 이상의 연구과제 책임자
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          중형 연구과제 기획 및 연구과제 책임자
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          단일 연구팀을 관리하는 연구자<br/>
                          (경력 8년 이상 20년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('1', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          3개년 이상 다년도 연구사업 (과제) 책임자
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          대형 연구사업(과제) 기획 및 연구사업(과제) 책임자
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연구단(소), 연구본부 등 기관 연구조직을 총괄 관리하는 연구자
                          (경력 20년 이상)
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={clsx(styles.cdrsTabCont, {[styles.show]: activeTab === "행정직"})}>
                <div className={styles.studyInfoTable}>
                  <table>
                    <caption>행정직 경력단계 정보로 구분, 신진, 중견, 리더로 구성되어있습니다.</caption>
                    <colgroup>
                      <col className={styles.col01}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti}>{t("구분")}</span>
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('5', '1') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("신진")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('5', '1') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('5', '2') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("중견")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('5', '2') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('5', '3') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("리더")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('5', '3') && (
                          <div className={styles.myCdrLayer}>
                            <span className={styles.txtSti_s}>나의 경력 단계</span>
                          </div>
                        )}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>경력지향</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정업무의 숙련도를 향상하여 업무수행 영역을 확장하고, 단일부서 리더 역할을 준비하는 행정직
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정업무 전반에 대해 업무 전문성을 인정받고 향후 기관 행정분야 책임자로서 역할을 준비하는 행정직
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정업무 분야에서 최상의 업무성과를 창출하고, 행정조직 총괄책임자로서 기관의 안정적 운영과 발전을 주도하는 행정직
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>업무경력</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정분야 단위직무를 수행하는 행정직<br/>
                          (경력 10년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          3개 이상의 서로 다른 행정분야별 단위직무를 수행한 행정직
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          부서장 또는 부서장 수행경험이 있는 행정직<br/>(경력 10년 이상 25년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('5', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정분야 단위직무를 7개 이상 수행한 행정직
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          3개 내외의 부서장 수행경험이 있는 행정직
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          행정업무 분야 최상위 부서장 또는 최상위 부서장 수행경험이 있는 행정직<br></br>
                          (경력 25년 이상)
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={clsx(styles.cdrsTabCont, {[styles.show]: activeTab === "연구관리직"})}>
                <div className={styles.studyInfoTable}>
                  <table>
                    <caption>연구관리직 경력단계 정보로 구분, 신진, 중견, 리더로 구성되어있습니다.</caption>
                    <colgroup>
                      <col className={styles.col01}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                      <col className={styles.col02}/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti}>{t("구분")}</span>
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('2', '1') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("신진")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('2', '1') && <div className={styles.myCdrLayer}>
                          <span className={styles.txtSti_s}>나의 경력 단계</span>
                        </div>}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('2', '2') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("중견")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('2', '2') && <div className={styles.myCdrLayer}>
                          <span className={styles.txtSti_s}>나의 경력 단계</span>
                        </div>}
                      </th>
                      <th scope="col" className={fnCheckIsMyCareerLevelAndJobField('2', '3') ? styles.myCdr : ""}>
                        <span className={styles.txtSti}>{t("리더")}</span>
                        {fnCheckIsMyCareerLevelAndJobField('2', '3') && <div className={styles.myCdrLayer}>
                          <span className={styles.txtSti_s}>나의 경력 단계</span>
                        </div>}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>경력지향</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          업무분야별 필요역량을 개발하여 연구관리 분야 전문가 또는 단일부서 리더 역할을 준비하는 연구관리직
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연구관리 업무 전반에 대한 전문성을 인정받고 향후 조직(본부/단)단위를 총괄하는 관리자로서의 역할을 준비하는 연구관리직
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          연구관리 업무분야에서 대내외적으로 인정받는 성과를 창출하고, 국가적 관점의 연구관리 분야 발전을 주도하는 연구관리직
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="col">
                        <span className={styles.txtSti_s}>업무경력</span>
                      </th>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '1') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          단일 연구과제의 연구관리 각 분야별 업무수행 또는 연구관리 전반을 지원하는 연구관리직<br/>(경력 8년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '2') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          복수의 연구사업(과제) 연구관리 전반을 총괄 전담하는 연구관리직
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          부서장 또는 부서장 수행경험이 있는 연구관리직<br/>(경력 8년 이상 20년 미만)
                        </span>
                      </td>
                      <td className={fnCheckIsMyCareerLevelAndJobField('2', '3') ? styles.myCdr : ""}>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          최상위 부서장 등 조직(본부/단) 단위 연구사업 연구관리 업무를 총괄하는 연구관리직
                        </span>
                        <span className={clsx(styles.txtSti_s, styles.dot)}>
                          3개 내외의 부서장 또는 최상위 부서장 수행경험이 있는 연구관리직<br/>(경력 20년 이상)
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className={styles.cdrsGuideWrap}>
              <span className={styles.txtGuide}>※ 직무를 '기타'로 선택하셨다면, 입사 기준으로 경력단계를 선택해주세요.</span>
            </div>
            <div className={styles.cdrsGuideWrap}>
              {!isPreview && (
                <>
                  <span className={styles.txtGuide}>나의 정보가 다르다면 내정보 수정에서 정보를 변경해 주세요.</span>
                  <a href="/my/profile" className={styles.btnBlue02Small}>내 정보 수정</a>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={styles.cdrPop}>
          <div className={styles.bottomBtnWrap}>
            {(changeMyCdrStep ?
              <button type="button" className={styles.btnBlue} onClick={() => changeMyCdrStep(myCareerLevel)}>관련과정 보기</button>
            :
              <button type="button" className={styles.btnBlue} onClick={() => onClose()}>확인</button>
            )}
          </div>
        </div>
      </DialogActions>
    </KirdModal>
  );
}